import { TextField } from '@mui/material'
import { memo } from 'react'
import { useDispatch } from 'react-redux'

import { stashTarget } from '../../../reducers/stashSlice'

const SetLabel = memo(({description = '', nodeUri}) => {
    const dispatch = useDispatch()

    const handleChange = (event) => dispatch(
        stashTarget({
            nodeUri,
            data: {
                description: event.target.value,
            },
        })
    )

    return (
        <TextField
            label="Label"
            margin="normal"
            onChange={handleChange}
            value={description}
            fullWidth />
    )
})

export default SetLabel
