import useMediaQuery from '@mui/material/useMediaQuery'
import { AppBar, Avatar, Box, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material'
import { Fragment, memo, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Dashboard as DashboardIcon, Menu as MenuIcon } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'

import UserMenu from './UserMenu'
import useCurrentUser from '../../hooks/useCurrentUser'
import { drawerWidth } from '../../common/theme'
import { useGetDashboardQuery } from '../../services/dashboard'

const Nav = memo(() => {
    const theme = useTheme()
    const isLarge = useMediaQuery(theme.breakpoints.up('lg'))

    const { client = {} } = useCurrentUser()
    const { data = {} } = useGetDashboardQuery()

    const [ mobileOpen, setMobileOpen ] = useState(false)

    const handleDrawerToggle = () => setMobileOpen((currentValue) => ! currentValue)

    const modalOpen = mobileOpen || isLarge

    return (
        <Fragment>
            <AppBar sx={barStyles.container}>
                <Toolbar sx={barStyles.wrapper}>
                    <Box>
                        {! isLarge && (
                            <IconButton color="secondary" onClick={handleDrawerToggle}>
                                <MenuIcon />
                            </IconButton>
                        )}
                    </Box>
                    <UserMenu />
                </Toolbar>
            </AppBar>
            <Box component="nav" sx={drawerStyles.container}>
                <Drawer ModalProps={{keepMounted: true}} anchor="left" onClose={handleDrawerToggle} sx={drawerStyles.wrapper} open={modalOpen} variant={isLarge ? 'permanent' : 'temporary'}>
                    <Box sx={drawerStyles.titleWrapper}>
                        <Box component={RouterLink} to="/">
                            <Avatar
                                alt={client.name}
                                src={client.logo} />
                        </Box>
                        <Typography ml={2} variant="h6" noWrap>{data?.map?.name}</Typography>
                    </Box>
                    <List component="nav">
                        <ListItemButton component={RouterLink} to="/">
                            <ListItemIcon>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Dashboard" />
                        </ListItemButton>
                    </List>
                </Drawer>
            </Box>
        </Fragment>
    )
})

const barStyles = {
    container: {
        width: {
            lg: `calc(100% - ${drawerWidth + 1}px)`,
        },
    },
    wrapper: {
        alignItems: 'center',
        dispaly: 'flex',
        justifyContent: 'space-between',
    },
}

const drawerStyles = {
    container: {
        flexShrink: {
            sm: 0,
        },
        width: {
            lg: drawerWidth,
        },
    },
    wrapper: {
        '& .MuiDrawer-paper': {
            width: drawerWidth,
        },
    },
    titleWrapper: {
        alignItems: 'center',
        display: 'flex',
        pb: 2,
        pt: 3,
        px: 2.5,
    },
    text: {
        fontSize: '0.875rem',
    },
    childWrapper: {
        pl: 4,
    },
}

export default Nav
