import { Box, CircularProgress } from '@mui/material'
import { Navigate, Outlet } from 'react-router-dom'
import { alpha } from '@mui/material/styles'
import { memo } from 'react'
import { useSelector } from 'react-redux'

import Nav from './Nav/Nav'
import { drawerWidth } from '../common/theme'
import { useLogoutMutation } from '../services/auth'

const ProtectedLayout = memo(() => {
    const { isAuthed } = useSelector(({auth}) => auth)

    const [ , { isLoading: isLogoutLoading } ] = useLogoutMutation({
        fixedCacheKey: 'shared-logout',
    })

    if (! isAuthed) {
        return (
            <Navigate
                to="/login" />
        )
    }

    return (
        <Box sx={styles.container}>
            <Nav />
            <Box sx={styles.wrapper}>
                <Outlet />
            </Box>
            {isLogoutLoading && (
                <Box sx={styles.overlay}>
                    <CircularProgress />
                </Box>
            )}
        </Box>
    )
})

const styles = {
    container: {
        display: 'flex',
        minHeight: '100vh',
    },
    wrapper: {
        flexGrow: 1,
        minHeight: '100%',
        pb: 10,
        pt: (theme) => ({
            xs: `${64 + parseInt(theme.spacing(3))}px`,
            lg: `${92 + parseInt(theme.spacing(3))}px`,
        }),
        width: {
            lg: `calc(100% - ${drawerWidth + 1}px)`,
        },
    },
    overlay: {
        alignItems: 'center',
        backgroundColor: (theme) => alpha(theme.palette.text.primary, 0.8),
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        left: 0,
        position: 'fixed',
        right: 0,
        top: 0,
        zIndex: 99999,
    },
}

export default ProtectedLayout
