import { USER_TAG } from '../common/tagTypes'
import { api } from './api'

export const userApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getUser: builder.query({
            query: (id) => ({
                url: `/user/${id}`,
            }),
            providesTags: ({id}) => [{
                type: USER_TAG,
                id,
            }],
        }),
        getTeamUsers: builder.query({
            query: (teamUri) => ({
                url: `/team/${teamUri}/user`,
            }),
            providesTags: ({id}) => [{
                type: USER_TAG,
                id,
            }],
        }),
        updateUser: builder.mutation({
            query: ({id, profilePicture, ...rest}) => {
                const formData = new FormData()
                formData.append('_method', 'PUT')

                Object.keys(rest).forEach((item) => formData.append(item, rest[item]))
                if (profilePicture) {
                    formData.append('profile_picture', profilePicture)
                }

                return {
                    url: `/user/${id}`,
                    method: 'POST',
                    body: formData,
                }
            },
            invalidatesTags: (_, __, {id}) => [{
                type: USER_TAG,
                id,
            }],
        }),
    }),
})

export const { useGetUserQuery, useGetTeamUsersQuery, useUpdateUserMutation } = userApi
