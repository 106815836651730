import PropTypes from 'prop-types'
import { Box, Paper, Typography } from '@mui/material'
import { memo } from 'react'

const Item = memo(({item, ...rest}) => {
    const styles = useStyles()

    return (
        <Paper elevation={0} {...rest}>
            {Boolean(item.feature_image) && (
                <Box sx={styles.imageWrapper}>
                    <Box
                        component="img"
                        src={item.feature_image}
                        sx={styles.image} />
                </Box>
            )}
            <Typography variant="subtitle2" gutterBottom>{item.title}</Typography>
            <Typography color="text.secondary" variant="body2">{item.excerpt}</Typography>
        </Paper>
    )
})

const useStyles = () => ({
    imageWrapper: {
        borderRadius: 1,
        marginBottom: 3,
        overflow: 'hidden',
        position: 'relative',
        paddingTop: '60%',
    },
    image: {
        height: '100%',
        objectFit: 'cover',
        position: 'absolute',
        top: 0,
        width: '100%',
    },
})

Item.propTypes = {
    item: PropTypes.shape({
        excerpt: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
}

export default Item
