import PropTypes from 'prop-types'
import { AlarmOff as AlarmOffIcon, Check as CheckIcon } from '@mui/icons-material'
import { Box } from '@mui/material'
import { memo } from 'react'

import { useGetNodeQuery } from '../../services/node'

const Icon = memo(({nodeUri, size = 40}) => {
    const { data = {}, isLoading } = useGetNodeQuery(nodeUri)

    const duration = data.target_duration > 100 ? 1 : Math.round(data.target_duration) / 100
    const progress = data.target_progress > 100 ? 1 : Math.round(data.target_progress) / 100

    const styles = useStyles({duration, progress, size})

    if (isLoading) {
        return null
    }

    const targetComplete = progress >= 1
    const targetMissed = ! targetComplete && duration >= 1

    return (
        <Box sx={styles.wrapper}>
            <DurationCircle
                size={size}
                value={duration} />
            <ProgressCircle
                size={size}
                value={progress} />
            {targetMissed && (
                <AlarmOffIcon
                    color="warning"
                    fontSize="small" />
            )}
            {targetComplete && (
                <CheckIcon
                    color="success"
                    fontSize="small" />
            )}
        </Box>
    )
})

const DurationCircle = ({size, value}) => {
    const styles = useStyles({
        color: value >= 1 ? 'error.light' : (value < .75 ? 'secondary.light' : 'warning.light'),
        size, value,
    })

    return (
        <Box sx={styles.outer}>
            <Box sx={[styles.inner, styles.innerLeft]} />
            <Box sx={[styles.inner, styles.innerRight]} />
        </Box>
    )
}

const ProgressCircle = ({size, value}) => {
    const styles = useStyles({
        color: 'success.light',
        size: size * .75,
        value,
    })

    return (
        <Box sx={styles.outer}>
            <Box sx={[styles.inner, styles.innerLeft]} />
            <Box sx={[styles.inner, styles.innerRight]} />
        </Box>
    )
}

const useStyles = ({color, size, value}) => ({
    wrapper: {
        alignItems: 'center',
        backgroundColor: 'background.paper',
        borderRadius: size,
        display: 'flex',
        height: size,
        justifyContent: 'center',
        position: 'relative',
        width: size,
    },
    outer: {
        clip: value > 0.5 ? undefined : `rect(0, ${size}px, ${size}px, ${size / 2}px)`,
        height: size,
        position: 'absolute',
        width: size,
    },
    inner: {
        borderColor: color,
        borderRadius: `${size / 2}px`,
        borderStyle: 'solid',
        borderWidth: (theme) => theme.spacing(0.375),
        clip: `rect(0, ${size / 2}px, ${size}px, 0)`,
        height: size,
        position: 'absolute',
        width: size,
    },
    innerLeft: {
        display: value > 0.5 ? 'block' : 'none',
        transform: `rotate(${360 * value}deg)`,
    },
    innerRight: {
        transform: `rotate(${Math.min(360 * value, 180)}deg)`,
    },
})

Icon.propTypes = {
    nodeUri: PropTypes.string.isRequired,
    size: PropTypes.number,
}

export default Icon
