import PropTypes from 'prop-types'
import { Box, FormControl, IconButton, InputLabel, ListItemText, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material'
import { Help as HelpIcon } from '@mui/icons-material'
import { Fragment, memo } from 'react'
import { useDispatch } from 'react-redux'

import { stashTarget } from '../../../reducers/stashSlice'

const SelectUnits = memo(({format, nodeUri, prefix = '', suffix = ''}) => {
    const dispatch = useDispatch()
    const styles = useStyles()

    const formatLabel = useFormatLabel(format)

    const handleChangeFormat = (event) => dispatch(
        stashTarget({
            nodeUri,
            data: {
                format: event.target.value,
            },
        })
    )

    const handleChangePrefix = (event) => dispatch(
        stashTarget({
            nodeUri,
            data: {
                prefix: event.target.value,
            },
        })
    )
    const handleChangeSuffix = (event) => dispatch(
        stashTarget({
            nodeUri,
            data: {
                suffix: event.target.value,
            },
        })
    )

    const formatSupportsAdornments = ['decimal', 'integer'].includes(format)

    return (
        <Box sx={styles.wrapper}>
            <FormControl sx={styles.formatWrapper} fullWidth>
                <InputLabel>Format</InputLabel>
                <Select label="Format" onChange={handleChangeFormat} renderValue={() => formatLabel} sx={styles.select} value={format}>
                    <MenuItem value="integer">
                        <ListItemText>Whole number</ListItemText>
                        <Tooltip title="Any decimal values will automatically be rounded to the nearest whole number.">
                            <IconButton>
                                <HelpIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </MenuItem>
                    <MenuItem value="decimal">
                        <ListItemText>Decimal number</ListItemText>
                        <Tooltip title="All values will be shown to two decimal places.">
                            <IconButton>
                                <HelpIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </MenuItem>
                    <MenuItem value="boolean">
                        <ListItemText>Met / not met</ListItemText>
                        <Tooltip title="All values will be interpreted as either true or false.">
                            <IconButton>
                                <HelpIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </MenuItem>
                </Select>
            </FormControl>
            {formatSupportsAdornments && (
                <Fragment>
                    <Typography variant="subtitle2" paragraph>Format your target value output (optional):</Typography>
                    <Box sx={styles.inputWrapper}>
                        <TextField
                            inputProps={{maxLength: 5}}
                            label="Prefix (e.g. £)"
                            onChange={handleChangePrefix}
                            value={prefix} />
                        <Tooltip title="For monetary values you may want a prefix like '£' or '€'">
                            <IconButton>
                                <HelpIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box sx={styles.inputWrapper}>
                        <TextField
                            inputProps={{maxLength: 20}}
                            label="Suffix (e.g. per day or %)"
                            onChange={handleChangeSuffix}
                            value={suffix} />
                        <Tooltip title="Add context to a value with a suffix like 'per day' or '%'">
                            <IconButton>
                                <HelpIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Fragment>
            )}
        </Box>
    )
})

const useFormatLabel = (format) => {
    switch (format) {
        case 'integer':
            return 'Whole number'
        case 'decimal':
            return 'Decimal number'
        case 'boolean':
            return 'Met / not met'
        default:
            return ''
    }
}

const useStyles = () => ({
    wrapper: {
        display: 'grid',
        gridAutoColumns: 'max-content',
    },
    formatWrapper: {
        marginBottom: 4,
    },
    select: {
        minWidth: 100,
    },
    inputWrapper: {
        alignItems: 'flex-end',
        display: 'grid',
        columnGap: 1,
        gridTemplateColumns: 'auto min-content',
        marginBottom: 3,
    },
})

SelectUnits.propTypes = {
    format: PropTypes.oneOf([
        'boolean', 'decimal', 'integer',
    ]),
    nodeUri: PropTypes.string.isRequired,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
}

export default SelectUnits
