import { Box, Container, Paper, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { memo, useState } from 'react'

import PasswordInput from '../components/PasswordInput'
import useCurrentUser from '../hooks/useCurrentUser'
import { useUpdateUserMutation } from '../services/user'

const Security = memo(() => {
    const { id } = useCurrentUser()

    const [ updateUser,  { error = {}, isError, isLoading } ] = useUpdateUserMutation()

    const [ currentPasswordValue, setCurrentPasswordValue ] = useState()
    const [ newPasswordValue, setNewPasswordValue ] = useState()
    const [ newPasswordConfirmValue, setNewPasswordConfirmValue ] = useState()

    const handleChangeCurrentPassword = (event) => setCurrentPasswordValue(event.target.value)
    const handleChangeNewPasswordValue = (event) => setNewPasswordValue(event.target.value)
    const handleChangeNewPasswordConfirmValue = (event) => setNewPasswordConfirmValue(event.target.value)

    const handleSubmit = (event) => {
        event.preventDefault()

        updateUser({
            id,
            current_password: currentPasswordValue,
            password: newPasswordValue,
            password_confirmation: newPasswordConfirmValue,
        })
    }

    const hasCurrentPasswordError = isError ? Boolean(error?.current_password) : false
    const hasNewPasswordError = isError ? Boolean(error?.password) : false
    const hasNewPasswordConfirmError = isError ? Boolean(error?.password_confirmation) : false

    return (
        <Container>
            <Box sx={styles.header}>
                <Typography variant="h4">Security</Typography>
            </Box>
            <Paper component="form" onSubmit={handleSubmit} sx={styles.wrapper}>
                <PasswordInput
                    autoComplete="current-password"
                    error={hasCurrentPasswordError}
                    helperText={error?.current_password}
                    label="Current password"
                    onChange={handleChangeCurrentPassword}
                    sx={styles.input}
                    fullWidth />
                <PasswordInput
                    autoComplete="new-password"
                    error={hasNewPasswordError}
                    helperText={error?.password}
                    label="New password"
                    onChange={handleChangeNewPasswordValue}
                    sx={styles.input}
                    fullWidth />
                <PasswordInput
                    autoComplete="new-password"
                    error={hasNewPasswordConfirmError}
                    helperText={error?.password_confirmation}
                    label="Confirm new password"
                    onChange={handleChangeNewPasswordConfirmValue}
                    sx={styles.input}
                    fullWidth />
                <LoadingButton loading={isLoading} sx={styles.button} type="submit" variant="contained">Update password</LoadingButton>
            </Paper>
        </Container>
    )
})

const styles = {
    header: {
        mb: 5,
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        p: 3,
    },
    input: {
        mb: 3,
    },
    button: {
        alignSelf: 'flex-end',
    },
}

export default Security
