import useMediaQuery from '@mui/material/useMediaQuery'
import { alpha } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles'

export const drawerWidth = 280

const useTheme = () => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

    const theme = createTheme({
        components: {
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        backgroundColor: prefersDarkMode ? 'rgba(20, 30, 35, 0.75)' : 'rgba(255, 255, 255, 0.75)',
                        backgroundImage: 'none',
                        backdropFilter: 'blur(6px)',
                        boxShadow: 'none',
                    },
                },
            },
            MuiBreadcrumbs: {
                styleOverrides: {
                    root: {
                        fontSize: '0.875rem',
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontSize: '0.9375rem',
                        fontWeight: '700',
                        height: 48,
                        textTransform: 'none',
                    },
                },
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        borderStyle: 'dashed',
                    },
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    label: {
                        fontSize: '0.875rem',
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        borderRadius: 6,
                        fontSize: '0.875rem',
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        boxShadow: 'rgba(145, 160, 170, .2) 0px 0px 2px 0px, rgba(145, 160, 170, .15) 0px 12px 24px -4px',
                    },
                },
            },
        },
        palette: {
            mode: prefersDarkMode ? 'dark' : 'light',
            background: {
                default: prefersDarkMode ? '#141e23' : '#fff',
                paper: prefersDarkMode ? '#141e23' : '#fff'
            },
            primary: {
                main: '#0abaab',
                contrastText: '#ffffff',
            },
            secondary: {
                main: '#91a0aa',
            },
            text: {
                primary: prefersDarkMode ? '#fff' : '#24252b',
                secondary: prefersDarkMode ? '#91a0aa' : '#647382',
            },
        },
        shape: {
            borderRadius: 8,
        },
        typography: {
            fontFamily: [
                '"Public Sans"', 'sans-serif',
            ].join(','),
        },
    })

    theme.typography = {
        ...theme.typography,
        h3: {
            ...theme.typography.h3,
            fontSize: '2rem',
            fontWeight: 700,
        },
        h4: {
            ...theme.typography.h4,
            fontSize: '1.25rem',
            fontWeight: 700,
            lineHeight: 1.5,
            [theme.breakpoints.up('md')]: {
                fontSize: '1.5rem',
            },
        },
        h6: {
            ...theme.typography.h6,
            fontSize: '1.0625rem',
            fontWeight: 700,
            [theme.breakpoints.up('md')]: {
                fontSize: '1.125rem',
            },
        },
        subtitle2: {
            ...theme.typography.subtitle2,
            fontWeight: 600,
        },
        caption: {
            ...theme.typography.caption,
            lineHeight: '1.5rem',
        },
    }
    theme.components.MuiToolbar = {
        ...theme.components.MuiToolbar,
        styleOverrides: {
            ...theme.components.MuiToolbar?.styleOverrides,
            root: {
                ...theme.components.MuiToolbar?.styleOverrides?.root,
                minHeight: 64,
                [theme.breakpoints.up('lg')]: {
                    minHeight: 92,
                    paddingLeft: 40,
                    paddingRight: 40,
                },
            },
        },
    }

    theme.components.MuiTypography = {
        ...theme.components.MuiTypography,
        styleOverrides: {
            ...theme.components.MuiTypography?.styleOverrides,
            root: {
                ...theme.components.MuiTypography?.styleOverrides?.root,
                color: theme.palette.text.primary,
            },
        },
    }

    theme.hoverShadow = `${alpha(prefersDarkMode ? alpha('#000', 0.25) : theme.palette.secondary.main, 0.2)} 0px 0px ${theme.spacing(0.25)} 0px, ${alpha(prefersDarkMode ? alpha('#000', 0.25) : theme.palette.secondary.main, 0.2)} 0px ${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(-0.5)}`

    return theme
}

export default useTheme
