import { NODES_TAG } from '../common/tagTypes'
import { api } from './api'

export const dashboardApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getDashboard: builder.query({
            query: () => ({
                url: '/dashboard',
            }),
            providesTags: [NODES_TAG],
        }),
        getTimeplan: builder.query({
            query: (nodeUri) => ({
                url: `/${nodeUri}/timeplan`,
            }),
            providesTags: [NODES_TAG],
        }),
    }),
})

export const { useGetDashboardQuery, useGetTimeplanQuery } = dashboardApi
