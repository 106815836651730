import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { memo } from 'react'

const ChoiceAnswer = memo(({choice = {}}) => {
    return (
        <Typography variant="subtitle2">{choice.label}</Typography>
    )
})

ChoiceAnswer.propTypes = {
    choice: PropTypes.shape({
        label: PropTypes.string.isRequired,
    }).isRequired,
}

export default ChoiceAnswer
