import { AddAPhoto as AddAPhotoIcon } from '@mui/icons-material'
import { Avatar, Box, Container, Fade, Paper, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { alpha } from '@mui/material/styles'
import { memo, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import useCurrentUser from '../hooks/useCurrentUser'
import { useUpdateUserMutation } from '../services/user'

const Security = memo(() => {
    const { email, first_name: firstName, id, last_name: lastName, profile_picture: profilePicture } = useCurrentUser()

    const [ updateUser, { error = {}, isError, isLoading } ] = useUpdateUserMutation()

    const [ isMouseOver, setIsMouseOver ] = useState(false)
    const [ profilePhotoPreview, setProfilePhotoPreview ] = useState()

    const [ emailValue, setEmailValue ] = useState(email)
    const [ firstNameValue, setFirstNameValue ] = useState(firstName)
    const [ lastNameValue, setLastNameValue ] = useState(lastName)
    const [ profilePhotoValue, setProfilePhotoValue ] = useState()

    const handleDrop = (acceptedFiles) => {
        setProfilePhotoValue(acceptedFiles[0])

        setProfilePhotoPreview(
            URL.createObjectURL(acceptedFiles[0])
        )
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        multiple: false,
        onDrop: handleDrop,
    })

    const handleSubmit = (event) => {
        event.preventDefault()

        updateUser({
            email: emailValue,
            first_name: firstNameValue,
            last_name: lastNameValue,
            profilePicture: profilePhotoValue,
            id,
        })
    }

    const handleMouseOut = () => setIsMouseOver(false)
    const handleMouseOver = () => setIsMouseOver(true)

    const handleChangeFirstName = (event) => setFirstNameValue(event.target.value)
    const handleChangeLastName = (event) => setLastNameValue(event.target.value)
    const handleChangeEmail = (event) => setEmailValue(event.target.value)

    const hasFirstNameError = isError ? Boolean(error?.first_name) : false
    const hasLastNameError = isError ? Boolean(error?.last_name) : false
    const hasEmailError = isError ? Boolean(error?.email) : false

    return (
        <Container>
            <Box sx={styles.header}>
                <Typography variant="h4">Profile</Typography>
            </Box>
            <Box component="form" onSubmit={handleSubmit} sx={styles.wrapper}>
                <Paper sx={styles.avatarContainer}>
                    <Box onMouseOut={handleMouseOut} onMouseOver={handleMouseOver} sx={styles.avatarWrapper} {...getRootProps()}>
                        <input
                            {...getInputProps()} />
                        <Avatar
                            src={profilePhotoPreview || profilePicture}
                            sx={styles.avatar({isDragActive})} />
                        <Fade in={isMouseOver}>
                            <Box sx={styles.avatarOverlay}>
                                <AddAPhotoIcon />
                                <Typography color="inherit" variant="caption">Select photo</Typography>
                            </Box>
                        </Fade>
                    </Box>
                    <Typography variant="caption">Select a JPEG, PNG or GIF image.<br />Maximum size 5MB.</Typography>
                </Paper>
                <Paper sx={styles.fieldsContainer}>
                    <TextField
                        error={hasFirstNameError}
                        helperText={error?.first_name}
                        label="First name"
                        onChange={handleChangeFirstName}
                        sx={styles.input}
                        value={firstNameValue}
                        fullWidth />
                    <TextField
                        error={hasLastNameError}
                        helperText={error?.last_name}
                        label="Last name"
                        onChange={handleChangeLastName}
                        sx={styles.input}
                        value={lastNameValue}
                        fullWidth />
                    <TextField
                        error={hasEmailError}
                        helperText={error?.email}
                        label="E-mail address"
                        onChange={handleChangeEmail}
                        sx={styles.input}
                        value={emailValue}
                        fullWidth />
                    <LoadingButton loading={isLoading} sx={styles.button} type="submit" variant="contained">Update profile</LoadingButton>
                </Paper>
            </Box>
        </Container>
    )
})

const styles = {
    header: {
        mb: 5,
    },
    wrapper: {
        columnGap: 3,
        display: 'grid',
        gridTemplateColumns: {
            md: 'auto 1fr',
        },
        gridAutoFlow: {
            md: 'column',
        },
        rowGap: 3,
    },
    avatarContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        px: 7,
        py: 10,
    },
    avatarWrapper: {
        alignItems: 'center',
        borderColor: (theme) => alpha(theme.palette.text.secondary, 0.35),
        borderRadius: '100%',
        borderStyle: 'dashed',
        borderWidth: 1,
        cursor: 'pointer',
        display: 'flex',
        height: 145,
        justifyContent: 'center',
        mb: 2,
        position: 'relative',
        width: 145,
    },
    avatar: ({isDragActive}) => ({
        height: 125,
        opacity: isDragActive ? 0.5 : 1,
        width: 125,
    }),
    avatarOverlay: ({
        alignItems: 'center',
        backgroundColor: (theme) => alpha(theme.palette.grey[900], 0.65),
        borderRadius: 125,
        color: (theme) => theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
        height: 125,
        justifyContent: 'center',
        position: 'absolute',
        width: 125,
    }),
    fieldsContainer: {
        display: 'flex',
        flexDirection: 'column',
        p: 3,
    },
    input: {
        mb: 3,
    },
    button: {
        alignSelf: 'flex-end',
    },
}

export default Security
