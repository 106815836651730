import { NODE_TAG, TARGET_TAG } from '../common/tagTypes'
import { api } from './api'

export const targetApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getTarget: builder.query({
            query: (nodeUri) => ({
                url: `/node/${nodeUri}/target`,
            }),
            providesTags: (result) => result ? [{
                type: TARGET_TAG,
                id: result.id,
            }] : [],
        }),
        storeTarget: builder.mutation({
            query: ({nodeUri, data: body}) => ({
                url: `/node/${nodeUri}/target`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (_result, _error, {nodeUri}) => [{
                type: NODE_TAG,
                id: nodeUri,
            }],
        }),
    }),
})

export const { useGetTargetQuery, useStoreTargetMutation } = targetApi
