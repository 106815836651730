import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    targets: {},
}

export const stashSlice = createSlice({
    name: 'stash',
    initialState,
    reducers: {
        stashTarget: (state, {payload}) => {
            state.targets = {
                ...state.targets,
                [payload.nodeUri]: {
                    ...state.targets[payload.nodeUri] || {},
                    ...payload.data,
                },
            }
        },
    },
})

export const { stashTarget } = stashSlice.actions

export default stashSlice.reducer
