import Chart from 'react-apexcharts'
import { Box, Container } from '@mui/material'
import { memo, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Loadable from '../components/Loadable'
import { useGetTimeplanQuery } from '../services/dashboard'

const Timeplan = memo(() => {
    const { nodeUri = '' } = useParams()
    const { data, error, isLoading, isSuccess, refetch } = useGetTimeplanQuery(nodeUri)

    const [ seriesData, setSeriesData ] = useState([])

    useEffect(() => {
        if (isLoading || ! isSuccess) {
            return
        }

        setSeriesData(
            data.nodes.filter(({active_target}) => active_target).map(({name, target_due, target_started}) => ({
                x: name,
                y: [
                    new Date(target_started).getTime(),
                    new Date(target_due).getTime(),
                ],
            }))
        )
    }, [isLoading, isSuccess]) // eslint-disable-line react-hooks/exhaustive-deps

    const options = {
        chart: {
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
                distributed: true,
                dataLabels: {
                    hideOverflowingLabels: false
                },
            },
        },
        xaxis: {
            type: 'datetime',
        },
    }

    const series = [
        {
            data: seriesData,
        },
    ]

    return (
        <Loadable error={error} isLoading={isLoading} refetch={refetch}>
            <Container sx={styles.container}>
                <Box>
                    <Chart
                        options={options}
                        series={series}
                        type="rangeBar" />
                </Box>
            </Container>
        </Loadable>
    )
})

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100%',
        overflow: 'hidden',
        width: (theme) => ({
            xs: '100%',
            md: theme.breakpoints.values.md,
        }),
    },
}

export default Timeplan
