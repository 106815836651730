import { api } from './api'

export const contentApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getContent: builder.query({
            query: (url) => ({
                url: `/content/${url}`,
            }),
        }),
    }),
})

export const { useGetContentQuery } = contentApi
