import { useSelector } from 'react-redux'
import { useGetTargetQuery } from '../services/target'

const useStashedTarget = (nodeUri) => {
    const stash = useSelector(({stash}) => stash.targets[nodeUri]) || {}

    const { data, ...rest } = useGetTargetQuery(nodeUri)

    return {
        data: {
            ...data,
            ...stash,
        },
        ...stash,
        ...rest
    }
}

export  default useStashedTarget
