import PropTypes from 'prop-types'
import { Check as CheckIcon, Clear as ClearIcon } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { memo } from 'react'

const BooleanAnswer = memo(({boolean = false}) => {
    return (
        <Box sx={styles.wrapper}>
            {boolean ? (
                <CheckIcon
                    color="success"
                    sx={styles.icon} />
            ) : (
                <ClearIcon
                    color="error"
                    sx={styles.icon} />
            )}
            <Typography variant="subtitle2">{boolean ? 'Yes' : 'No'}</Typography>
        </Box>
    )
})

const styles = {
    wrapper: {
        alignItems: 'flex-end',
        display: 'flex',
    },
    icon: {
        mr: 1,
    },
}

BooleanAnswer.propTypes = {
    boolean: PropTypes.bool.isRequired,
}

export default BooleanAnswer
