import { memo, useState } from 'react'
import { Box, Checkbox, Container, FormControl, FormControlLabel, FormGroup, FormHelperText, InputLabel, Link, OutlinedInput, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { useDispatch } from 'react-redux'

import PasswordInput from '../components/PasswordInput'
import { setCredentials } from '../reducers/authSlice'
import { storeToken, useLoginMutation } from '../services/auth'

const Login = memo(() => {
    const dispatch = useDispatch()

    const [ email, setEmail ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ remember, setRemember ] = useState(true)

    const [ login, { error, isError, isLoading } ] = useLoginMutation()

    const handleChangeEmail = (event) => setEmail(event.target.value)
    const handleChangePassword = (event) => setPassword(event.target.value)
    const handleChangeRemember = (event) => setRemember(event.target.checked)

    const handleSubmit = (event) => {
        event.preventDefault()

        login({email, password}).unwrap().then((data) => {
            storeToken(data.token, remember)
            dispatch(setCredentials({
                ...data,
                remember,
            }))
        })
    }

    const hasEmailError = isError ? Boolean(error?.email) : false
    const hasPasswordError = isError ? Boolean(error?.password) : false

    return (
        <Container maxWidth="sm" sx={styles.wrapper}>
            <Box sx={styles.titleWrapper}>
                <Typography variant="h4" gutterBottom>Sign in to your Strategy Hub</Typography>
                <Typography sx={styles.subtitle}>Enter your credentials below.</Typography>
            </Box>
            <Box component="form" onSubmit={handleSubmit}>
                <FormControl sx={styles.formControl} fullWidth>
                    <InputLabel>Email</InputLabel>
                    <OutlinedInput
                        autoComplete="username"
                        error={hasEmailError}
                        label="Email"
                        onChange={handleChangeEmail}
                        value={email} />
                    {hasEmailError && (
                        <FormHelperText error>{error.email}</FormHelperText>
                    )}
                </FormControl>
                <PasswordInput
                    autoComplete="current-password"
                    error={hasPasswordError}
                    helperText={error?.password}
                    label="Password"
                    onChange={handleChangePassword}
                    sx={styles.formControl}
                    value={password}
                    fullWidth />
                <Box sx={styles.linksWrapper}>
                    <FormGroup>
                        <FormControlLabel control={(
                            <Checkbox
                                checked={remember}
                                onChange={handleChangeRemember} />
                        )} label="Remember me" />
                    </FormGroup>
                    <Link component={RouterLink} to="/forgot" underline="none" variant="subtitle2">Forgot password?</Link>
                </Box>
                <LoadingButton loading={isLoading} type="submit" variant="contained" fullWidth>Login</LoadingButton>
            </Box>
        </Container>
    )
})

const styles = {
    wrapper: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        margin: 'auto',
        maxWidth: 480,
    },
    titleWrapper: {
        mb: 5,
    },
    subtitle: {
        color: 'text.secondary',
    },
    formControl: {
        mb: 3,
    },
    linksWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        mb: 2,
        mt: -1,
    },
}

export default Login
