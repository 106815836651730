import { Typography } from '@mui/material'
import { memo } from 'react'

const Value = memo(({format, prefix, suffix, value, ...rest}) => {
    const showPrefix = ['decimal', 'integer'].includes(format)
    const showSuffix = ['decimal', 'integer'].includes(format)

    const valueOutput = format === 'boolean' ? (value ? 'Met' : 'Not met') : parseFloat(value).toLocaleString(undefined, {
        maximumFractionDigits: format === 'decimal' ? 2 : 0,
    })

    return (
        <Typography {...rest}>
            {showPrefix && (
                prefix
            )}
            {valueOutput}
            {Boolean(suffix && showSuffix && suffix !== '%') && ' '}
            {showSuffix && (
                <Typography component="span" variant="caption">{suffix}</Typography>
            )}
        </Typography>
    )
})

export default Value
