import PropTypes from 'prop-types'
import { Box, Button, Fab, Paper, Skeleton, Tooltip, Typography } from '@mui/material'
import { Fragment, memo } from 'react'
import { Link } from 'react-router-dom'
import { ViewTimeline as ViewTimelineIcon } from '@mui/icons-material'
import { alpha } from '@mui/material/styles'

import ContentItem from '../Content/Item'
import OwnerButton from '../Owner/Button'
import TargetButton from '../Target/Button'
import useNodes from '../../hooks/useNodes'
import { useGetNodeQuery } from '../../services/node'

const Node= memo(({horizontal = false, name, uri}) => {
    const styles = useStyles({horizontal})

    const { childNodes, defaultNodeName } = useNodes(uri)
    const { data = {}, isLoading } = useGetNodeQuery(uri)

    const content = data.content || []
    const hasChildren = Boolean(childNodes.length)

    return (
        <Paper sx={styles.wrapper} variant="outlined">
            <Box sx={styles.titleWrapper}>
                <Typography sx={styles.title} variant="h6">{name || defaultNodeName}</Typography>
                {hasChildren && (
                    <Button component={Link} size="large" to={`/${uri}`} variant="contained">{childNodes.length} Sub-Goals</Button>
                )}
            </Box>
            {isLoading ? (
                <Skeleton
                    sx={styles.placeholder}
                    variant="rectangular" />
            ) : (
                <Fragment>
                    <Box sx={styles.header}>
                        <TargetButton
                            nodeUri={uri} />
                        <OwnerButton
                            nodeUri={uri} />
                        <Tooltip title="Time Plan">
                            <Fab color="secondary" component={Link} size="small" state={{modal: true}} to={`/${uri}/timeplan`}>
                                <ViewTimelineIcon />
                            </Fab>
                        </Tooltip>
                    </Box>
                    <Box sx={styles.items}>
                        {content.map((item) => {
                            const path = `/content/${item.slug}`

                            return (
                                <ContentItem
                                    component={Link}
                                    item={item}
                                    key={item.id}
                                    state={{modal: true}}
                                    sx={styles.item}
                                    to={path} />
                            )
                        })}
                    </Box>
                </Fragment>
            )}
        </Paper>
    )
})

const useStyles = ({horizontal}) => ({
    wrapper: {
        backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.1),
        borderColor: (theme) => alpha(theme.palette.secondary.main, 0.25),
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        paddingY: 2,
        paddingX: 2,
    },
    titleWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 3,
    },
    title: {
        flex: 1,
        overflow: 'hidden',
        paddingRight: 2,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    placeholder: {
        bgcolor: 'common.white',
        borderRadius: 1,
        height: (theme) => theme.spacing(20),
        marginBottom: 3,
        width: '100%',
    },
    header: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridAutoColumns: 'min-content',
        columnGap: 3,
        marginBottom: 4,
        paddingX: 1,
    },
    items: {
        columnGap: 3,
        display: 'grid',
        rowGap: 3,
        gridTemplateColumns: {
            lg: horizontal ? 'repeat(3, 1fr)' : undefined,
        },
    },
    item: {
        cursor: 'pointer',
        display: 'block',
        paddingX: 3,
        paddingY: 3,

        ':hover': {
            boxShadow: (theme) => theme.hoverShadow,
        },
    },
})

Node.propTypes = {
    horizontal: PropTypes.bool,
    name: PropTypes.string,
    uri: PropTypes.string.isRequired,
}

export default Node
