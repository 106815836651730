import { Box, Button, Container, Typography } from '@mui/material'
import { memo } from 'react'

const Error = memo(({refetch}) => {
    return (
        <Container maxWidth="lg" sx={styles.container}>
            <Box sx={styles.wrapper}>
                <Typography align="center" sx={styles.heading} variant="h3">Sorry, something has gone wrong!</Typography>
                <Typography align="center" color="text.secondary" sx={styles.subheading}>Something went wrong whilst loading your requested resource. Hopefully it will work if you try that again.</Typography>
                <Button onClick={refetch} variant="contained">Try again</Button>
            </Box>
        </Container>
    )
})

const styles = {
    container: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        minHeight: '100%',
    },
    wrapper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 540,
        py: 3,
    },
    heading: {
        mb: 2,
    },
    subheading: {
        mb: 8,
    },
}

export default Error
