import { Avatar, Box, Divider, IconButton, InputLabel, FormControl, FormControlLabel, FormGroup, ListItemText, MenuItem, Select, Switch, Typography, useMediaQuery } from '@mui/material'
import { ChevronLeft as ChevronLeftIcon, Person as PersonIcon } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { memo, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

import Loadable from '../components/Loadable'
import useCurrentUser from '../hooks/useCurrentUser'
import useGoBack from '../hooks/useGoBack'
import { useGetNodeQuery, useUpdateNodeMutation } from '../services/node'
import { useGetTeamUsersQuery } from '../services/user'

const Manage = memo(() => {
    const styles = useStyles()
    const theme = useTheme()
    const goBack = useGoBack()
    const isMedium = useMediaQuery(theme.breakpoints.up('md'))

    const { teams_index = [] } = useCurrentUser()

    const { nodeUri } = useParams()
    const { data: node = {}, isNodeLoading, refetch } = useGetNodeQuery(nodeUri)
    const { data: users = {}, isUsersLoading } = useGetTeamUsersQuery(Object.keys(teams_index)[0])
    const [ updateNode, { isLoading: isStoring, isSuccess } ] = useUpdateNodeMutation()

    const [ isAssigned, setIsAssigned ] = useState(Boolean(node.assigned_user))
    const [ assignedUser, setAssignedUser ] = useState(node.assigned_user ? node.assigned_user.id : '')

    useEffect(() => {
        if (isAssigned) {
            return
        }

        setAssignedUser('')
    }, [isAssigned])

    useEffect(() => {
        if (! isSuccess) {
            return
        }

        goBack()
    }, [isSuccess]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = () => updateNode({
        uri: nodeUri,
        data: {
            assigned_user: assignedUser,
        },
    })

    const handleChangeToggle = (event) => setIsAssigned(event.target.checked)
    const handleChangeSelect = (event) => setAssignedUser(event.target.value)

    const isLoading = isNodeLoading || isUsersLoading

    return (
        <Box sx={styles.wrapper}>
            <Box sx={styles.heading}>
                {! isMedium && (
                    <IconButton color="secondary" onClick={goBack}>
                        <ChevronLeftIcon />
                    </IconButton>
                )}
                <Avatar sx={styles.avatar} variant="rounded">
                    <PersonIcon />
                </Avatar>
                <Typography sx={styles.title} variant="subtitle2">Manage Owner</Typography>
            </Box>
            <Divider />
            <Loadable isLoading={isLoading} refetch={refetch}>
                <Box sx={styles.content}>
                    <FormGroup sx={styles.inputGroup}>
                        <FormControlLabel
                            control={<Switch checked={isAssigned} onChange={handleChangeToggle} />}
                            label="Assign an owner to this goal?" />
                    </FormGroup>
                    {isAssigned && (
                        <FormControl sx={styles.inputGroup} fullWidth>
                            <InputLabel>User</InputLabel>
                            <Select label="User" onChange={handleChangeSelect} renderValue={(selected) => users.find(({id}) => id === selected)?.name} value={assignedUser} >
                                {users.map(({id, name, profile_picture}) => {
                                    return (
                                        <MenuItem key={id} value={id}>
                                            <Avatar
                                                src={profile_picture}
                                                sx={styles.userAvatar} />
                                            <ListItemText>{name}</ListItemText>
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    )}
                    <LoadingButton loading={isStoring} onClick={handleSubmit} variant="contained" fullWidth>
                        Save
                    </LoadingButton>
                </Box>
            </Loadable>
        </Box>
    )
})

const useStyles = () => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100vh',
        overflow: 'hidden',
        width: (theme) => ({
            xs: '100%',
            md: theme.breakpoints.values.sm,
        }),
    },
    avatar: {
        backgroundColor: 'secondary.main',
    },
    heading: {
        alignItems: 'center',
        display: 'flex',
        minHeight: (theme) => theme.spacing(11.5),
        paddingX: 3,
    },
    title: {
        lineHeight: 'normal',
        marginLeft: 2,
    },
    content: {
        paddingX: 3,
        paddingY: 2,
    },
    inputGroup: {
        marginBottom: 4,
    },
    userAvatar: {
        marginRight: 2,
        marginY: 1,
    },
})

export default Manage
