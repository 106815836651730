import { Avatar, Box, Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { Fragment, memo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import useCurrentUser from '../../hooks/useCurrentUser'
import { forgetCookie, forgetCredentials } from '../../reducers/authSlice'
import { useLogoutMutation } from '../../services/auth'

const UserMenu = memo(() => {
    const dispatch = useDispatch()

    const { email, name, profile_picture: profilePicture } = useCurrentUser()

    const [ logout ] = useLogoutMutation({
        fixedCacheKey: 'shared-logout',
    })

    const [ anchorEl, setAnchorEl ] = useState()

    const handleClick = (event) => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null)

    const handleClickLogout = () => logout().unwrap().finally(() => {
        dispatch(forgetCredentials())
        forgetCookie()
    })

    const open = Boolean(anchorEl)

    return (
        <Fragment>
            <Box>
                <IconButton onClick={handleClick} sx={styles.avatarWrapper}>
                    <Avatar
                        src={profilePicture}
                        sx={styles.avatar} />
                </IconButton>
            </Box>
            <Menu anchorEl={anchorEl} anchorOrigin={{horizontal: 'right', vertical: 50}} onClick={handleClose} onClose={handleClose} open={open} transformOrigin={{horizontal: 'right', vertical: 'top'}}>
                <Box sx={styles.nameWrapper}>
                    <Typography variant="subtitle2" noWrap>{name}</Typography>
                    <Typography color="text.secondary" variant="body2" noWrap>{email}</Typography>
                </Box>
                <Divider light />
                <Box sx={styles.itemsWrapper}>
                    <MenuItem component={Link} to="/profile">
                        Profile
                    </MenuItem>
                    <MenuItem component={Link} to="/security">
                        Security
                    </MenuItem>
                </Box>
                <Divider light />
                <Box sx={styles.itemsWrapper}>
                    <MenuItem onClick={handleClickLogout}>
                        Logout
                    </MenuItem>
                </Box>
            </Menu>
        </Fragment>
    )
})

const styles = {
    avatarWrapper: {
        height: 44,
        padding: 0,
        ml: {
            xs: 0.5,
            sm: 1.5,
        },
        width: 44,
    },
    avatar: {
        height: 40,
        width: 40,
    },
    nameWrapper: {
        my: 1.5,
        px: 2.5,
    },
    itemsWrapper: {
        p: 1,
    },
}

export default UserMenu
