import { ChevronRight as ChevronRightIcon } from '@mui/icons-material'
import { Breadcrumbs as BaseBreadcrumbs, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { memo } from 'react'
import { useParams } from 'react-router-dom'

import useNodes from '../hooks/useNodes'

const Breadcrumbs = memo(({sx = {}}) => {
    const { nodeUri } = useParams()

    const { currentInstance, currentNode, instances, nodes } = useNodes(nodeUri)

    const parentInstances = []

    let parentInstance = instances.find(({id}) => id === currentInstance.parents[0])

    while (parentInstance) {
        parentInstances.push(parentInstance)

        parentInstance = instances.find(({id}) => id === parentInstance.parents[0])
    }

    const isRoot = nodeUri === undefined

    return (
        <BaseBreadcrumbs aria-label="breadcrumb" separator={<ChevronRightIcon fontSize="small" />} sx={sx}>
            <Link color={isRoot ? 'primary' : 'inherit'} component={isRoot ? undefined : RouterLink} to={isRoot ? undefined : '/'} underline={isRoot ? 'none' : 'hover'}>Dashboard</Link>
            {parentInstances.map(({id, node_id}, index) => (
                <Link color="inherit" component={RouterLink} key={id} to={`/${nodes.find(({id}) => id === node_id).uri}`} underline="hover">{nodes.find(({id}) => id === node_id).name}</Link>
            ))}
            {! isRoot && (
                <Link color="primary" underline="none">{currentNode.name}</Link>
            )}
        </BaseBreadcrumbs>
    )
})

export default Breadcrumbs
