import { memo } from 'react'

import BooleanAnswer from './BooleanAnswer'
import ChoiceAnswer from './ChoiceAnswer'
import DateAnswer from './DateAnswer'
import FileAnswer from './FileAnswer'
import TextAnswer from './TextAnswer'

const Answer = memo(({type, ...rest}) => {
    if (type === 'boolean') {
        return (
            <BooleanAnswer
                {...rest} />
        )
    }

    if (type === 'choice') {
        return (
            <ChoiceAnswer
                {...rest} />
        )
    }

    if (type === 'date') {
        return (
            <DateAnswer
                {...rest} />
        )
    }

    if (type === 'file_url') {
        return (
            <FileAnswer
                {...rest} />
        )
    }

    if (type === 'text') {
        return (
            <TextAnswer
                {...rest} />
        )
    }

    return null
})

export default Answer
