import Cookie from 'js-cookie'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import * as tagTypes from '../common/tagTypes'
import { API_URL } from '../common/config'
import { forgetCookie, forgetCredentials } from '../reducers/authSlice'

export const baseQuery = (baseUrl = '') => async (args, api, extraOptions) => {
    const baseResult = await fetchBaseQuery({
        baseUrl: `${API_URL}${baseUrl}`,
        prepareHeaders: async (headers, { getState }) => {
            headers.set('Accept', 'applicaiton/json')

            const { isAuthed } = getState().auth || {}

            if (isAuthed) {
                headers.set('Authorization', `Bearer ${Cookie.get('_token')}`)
            }

            return headers
        },
    })(args, api, extraOptions)

    const { auth = {} } = api.getState()

    if (auth.isAuthed && (baseResult.meta.response.status === 401 || (args.url === 'logout' && baseResult.meta.response.status === 204))) {
        api.dispatch(forgetCredentials())
        forgetCookie()
    }

    if (baseResult.error?.data?.errors) {
        baseResult.error = baseResult.error?.data?.errors
    } else if (baseResult.error) {
        baseResult.error = baseResult.error.data?.message || 'An unknown error has occurred.'
    }

    return baseResult
}

export const api = createApi({
    baseQuery: baseQuery(),
    refetchOnMountOrArgChange: 30,
    tagTypes: Object.values(tagTypes),
    endpoints: () => ({}),
})
