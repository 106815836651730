import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const useGoBack = () => {
    const navigate = useNavigate()

    const { lastLocation } = useSelector(({ui}) => ui)

    return (fallback = '/') => navigate(lastLocation || fallback)
}

export default useGoBack
