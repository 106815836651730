import React, { memo } from 'react'
import { Box, CircularProgress } from '@mui/material'

const Loading = memo(() => {
    return (
        <Box sx={styles.wrapper}>
            <CircularProgress />
        </Box>
    )
})

const styles = {
    wrapper: {
        alignItems: 'center',
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
    },
}

export default Loading
