import PropTypes from 'prop-types'
import { Avatar, Box, Button, Divider, IconButton, Step, Stepper, StepButton, StepContent, Typography, useMediaQuery } from '@mui/material'
import { ChevronLeft as ChevronLeftIcon, TrackChanges as TrackChangesIcon } from '@mui/icons-material'
import { Fragment, memo, useEffect, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

import Loadable from '../components/Loadable'
import Confirm from '../components/Target/Steps/Confirm'
import SelectAggregate from '../components/Target/Steps/SelectAggregate'
import SelectService from '../components/Target/Steps/SelectService'
import SelectUnits from '../components/Target/Steps/SelectUnits'
import SetDates from '../components/Target/Steps/SetDates'
import SetLabel from '../components/Target/Steps/SetLabel'
import SetValues from '../components/Target/Steps/SetValues'
import useGoBack from '../hooks/useGoBack'
import useStashedTarget from '../hooks/useStashedTarget'
import { useStoreTargetMutation } from '../services/target'

const Manage = memo(() => {
    const styles = useStyles()
    const theme = useTheme()
    const goBack = useGoBack()
    const isMedium = useMediaQuery(theme.breakpoints.up('md'))

    const { nodeUri } = useParams()
    const { data, isLoading, refetch } = useStashedTarget(nodeUri)
    const [ storeTarget, { isLoading: isStoring, isSuccess } ] = useStoreTargetMutation()

    const [ activeStep, setActiveStep ] = useState(0)

    const steps = useSteps(data)

    useEffect(() => {
        if (! isSuccess) {
            return
        }

        goBack()
    }, [isSuccess]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleNextStep = () => {
        if (steps.length - 1 <= activeStep) {
            return handleSubmit()
        }

        setActiveStep(activeStep + 1)
    }

    const handlePreviousStep = () => {
        if (activeStep === 0) {
            return
        }

        setActiveStep(activeStep - 1)
    }

    const handleSubmit = () => storeTarget({
        nodeUri,
        data: {
            date_due: data.date_due,
            date_initiated: data.date_initiated,
            description: data.description,
            format: data.format,
            prefix: data.prefix,
            service: data.service,
            start_value: data.start_value,
            suffix: data.suffix,
            value: data.value,
        },
    })

    return (
        <Box sx={styles.wrapper}>
            <Box sx={styles.heading}>
                {! isMedium && (
                    <IconButton color="secondary" onClick={goBack}>
                        <ChevronLeftIcon />
                    </IconButton>
                )}
                <Avatar sx={styles.avatar} variant="rounded">
                    <TrackChangesIcon />
                </Avatar>
                <Typography sx={styles.title} variant="subtitle2">Manage Target</Typography>
            </Box>
            <Divider />
            <Loadable isLoading={isLoading} refetch={refetch}>
                <Stepper activeStep={activeStep} orientation="vertical" sx={styles.content}>
                    {steps.map(({completed, description, disabled, key, label}, index) => {
                        const handleClick = () => setActiveStep(index)

                        return (
                            <Step completed={completed} disabled={disabled} key={`step-${index}`}>
                                <StepButton onClick={handleClick}>{label}</StepButton>
                                <StepContent>
                                    <Typography variant="subtitle2" paragraph>{description}</Typography>
                                    <Content
                                        nodeUri={nodeUri}
                                        stepKey={key}
                                        target={data} />
                                    <Box sx={styles.controls}>
                                        <Button color="secondary" disabled={index === 0} onClick={handlePreviousStep}>
                                            Back
                                        </Button>
                                        <LoadingButton disabled={! completed} loading={isStoring} onClick={handleNextStep}>
                                            {activeStep === steps.length - 1 ? 'Save' : 'Next'}
                                        </LoadingButton>
                                    </Box>
                                </StepContent>
                            </Step>
                        )
                    })}
                </Stepper>
            </Loadable>
        </Box>
    )
})

const Content = ({nodeUri, stepKey, target}) => {
    switch (stepKey) {
        case 'service':
            return (
                <SelectService
                    nodeUri={nodeUri}
                    {...target} />
            )
        case 'dates':
            return (
                <SetDates
                    nodeUri={nodeUri}
                    {...target} />
            )
        case 'units':
            return (
                <SelectUnits
                    nodeUri={nodeUri}
                    {...target} />
            )
        case 'aggregate':
            return (
                <SelectAggregate
                    nodeUri={nodeUri}
                    {...target} />
            )
        case 'values':
            return (
                <SetValues
                    nodeUri={nodeUri}
                    {...target} />
            )
        case 'confirm':
            return (
                <Fragment>
                    <SetLabel
                        nodeUri={nodeUri}
                        {...target} />
                    <Confirm
                        {...target} />
                </Fragment>
            )
        default:
            return null
    }
}

const useSteps = (target = {}) => {
    const steps = ([
        {
            label: 'Select target data source',
            description: 'Choose from our available integrations to populate your target data:',
            key: 'service',
        },
    ])

    steps.push(...[
        {
            label: 'Set target dates',
            description: 'Choose the dates this target should run from and to:',
            key: 'dates',
        },
        {
            label: 'Select target format',
            description: 'Choose how target values are formatted:',
            key: 'units',
        },
    ])

    if (target.service?.label === 'internal.aggregate') {
        steps.push({
            label: 'Select aggregate data',
            description: 'Choose which target values are used to form the aggregate value:',
            key: 'aggregate',
        })
    }

    if (target.service?.label !== 'internal.aggregate' && ! ['boolean'].includes(target.format)) {
        steps.push({
            label: 'Set target values',
            description: 'Provide the current and target values:',
            key: 'values',
        })
    }

    steps.push({
        label: 'Label and confirm',
        description: 'Label your target and confirm everything is correct:',
        key: 'confirm',
    })

    const completedSteps = steps.map(({key}) => {
        switch (key) {
            case 'service':
                return Boolean(target.service && target.service.label)
            case 'dates':
                return Boolean(target.date_initiated && target.date_due) && new Date(target.date_due).getTime() > new Date(target.date_initiated).getTime()
            case 'units':
                return Boolean(target.format)
            case 'aggregate':
                return Boolean(target.service && target.service.configs && target.service.configs && target.service.configs.filter(({key}) => key === 'node').length)
            case 'values':
                return Boolean((target.start_value === 0 || target.start_value) && (target.value === 0 || target.value))
            case 'confirm':
                return Boolean(target.description)
            default:
                return false
        }
    })

    return steps.map((item, index) => {
        return ({
            ...item,
            completed: completedSteps[index],
            disabled: index > 0 ? ! completedSteps[index - 1] : false,
        })
    })
}

const useStyles = () => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100vh',
        overflow: 'hidden',
        width: (theme) => ({
            xs: '100%',
            md: theme.breakpoints.values.sm,
        }),
    },
    avatar: {
        backgroundColor: 'secondary.main',
    },
    heading: {
        alignItems: 'center',
        display: 'flex',
        minHeight: (theme) => theme.spacing(11.5),
        paddingX: 3,
    },
    title: {
        lineHeight: 'normal',
        marginLeft: 2,
    },
    content: {
        overflowY: 'auto',
        paddingX: 3,
        paddingY: 2,
    },
    controls: {
        display: 'grid',
        gridAutoColumns: 'min-content',
        gridAutoFlow: 'column',
        columnGap: 2,
        marginTop: 4,
    },
})

export default Manage
