import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    lastLocation: null,
}

export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setLastLocation: (state, {payload}) => {
            state.lastLocation = payload
        }
    },
})

export const { setLastLocation } = uiSlice.actions

export default uiSlice.reducer
