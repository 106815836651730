import { Avatar, Box, Divider, IconButton, Skeleton, Typography, useMediaQuery } from '@mui/material'
import { Article as ArticleIcon, Close as CloseIcon } from '@mui/icons-material'
import { memo } from 'react'
import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

import Loadable from '../../components/Loadable'
import useGoBack from '../../hooks/useGoBack'
import useGhostContent from '../../hooks/useGhostContent'
import { useGetContentQuery } from '../../services/content'

const View = memo(() => {
    const styles = useStyles()
    const theme = useTheme()
    const isMedium = useMediaQuery(theme.breakpoints.up('md'))
    const goBack = useGoBack()

    const { slug } = useParams()

    const { data = {}, isLoading, refetch } = useGetContentQuery(slug)

    const markup = useGhostContent(data.html)

    return (
        <Box sx={styles.wrapper}>
            <Box sx={styles.heading}>
                {! isMedium && (
                    <IconButton color="secondary" onClick={goBack}>
                        <CloseIcon />
                    </IconButton>
                )}
                <Avatar sx={styles.avatar} variant="rounded">
                    <ArticleIcon />
                </Avatar>
                <Typography sx={styles.nodeName} variant="subtitle2">
                    {isLoading ? <Skeleton width={194} /> : data.node?.name}
                </Typography>
            </Box>
            <Divider />
            <Loadable isLoading={isLoading} refetch={refetch}>
                <Box sx={styles.body}>
                    {Boolean(data.feature_image) && (
                        <Box sx={styles.imageWrapper}>
                            <Box
                                component="img"
                                src={data.feature_image}
                                sx={styles.image} />
                        </Box>
                    )}
                    <Typography variant="h3" sx={styles.title} gutterBottom>{data.title}</Typography>
                    <Box sx={styles.content}>
                        {markup}
                    </Box>
                </Box>
            </Loadable>
        </Box>
    )
})

const useStyles = () => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100vh',
        overflow: 'hidden',
        width: (theme) => ({
            xs: '100%',
            md: theme.breakpoints.values.sm,
        }),
    },
    heading: {
        alignItems: 'center',
        display: 'flex',
        minHeight: (theme) => theme.spacing(11.5),
        px: 3,
    },
    avatar: {
        backgroundColor: 'secondary.main',
    },
    nodeName: {
        lineHeight: 'normal',
        marginLeft: 2,
    },
    body: {
        maxHeight: (theme) => `calc(100vh - ${theme.spacing(11.5)})`,
        overflowY: 'auto',
        paddingBottom: 3,
    },
    imageWrapper: {
        overflow: 'hidden',
        position: 'relative',
        pt: '40%',
    },
    image: {
        height: '100%',
        objectFit: 'cover',
        position: 'absolute',
        top: 0,
        width: '100%',
    },
    title: {
        mt: 3,
        px: 3,
    },
    content: {
        px: 3,
        a: {
            color: (theme) => theme.palette.primary.main,
        },
        figcaption: {
            color: (theme) => theme.palette.text.secondary,
            fontSize: '0.75rem',
            lineHeight: 1.5,
        },
        figure: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginBlockEnd: 3,
            marginBlockStart: 3,
            marginInlineEnd: 0,
            marginInlineStart: 0,
        },
        h3: {
            color: (theme) => theme.palette.text.primary,
            fontSize: '1.5rem',
            lineHeight: 1.5,
            marginBottom: '0.35em',
            marginTop: '0.7em',
        },
        h5: {
            color: (theme) => theme.palette.text.primary,
            fontSize: '1.125rem',
            fontWeight: '700',
            marginBottom: '0.35em',
            marginTop: '0.7em',
        },
        img: {
            borderRadius: 1,
            height: 'auto',
            mb: 1,
            width: '80%',
        },
        '> p': {
            color: (theme) => theme.palette.text.primary,
            fontSize: '1rem',
            lineHeight: 1.5,
            marginBottom: '0.35em',
            marginTop: 0,
        },
        strong: {
            fontWeight: '600',
        },
        ul: {
            marginBottom: '0.7em',
            marginTop: '0.7em',
        },
    },
})

export default View
