import PropTypes from 'prop-types'
import { memo } from 'react'

import Error from './Error'
import Loading from './Loading'

const Loadable = memo(({children, error, isLoading, refetch}) => {
    if (isLoading) {
        return (
            <Loading />
        )
    }

    if (error) {
        return (
            <Error
                refetch={refetch} />
        )
    }

    return children
})

Loadable.propTypes = {
    isLoading: PropTypes.bool,
}

export default Loadable
