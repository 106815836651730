import PropTypes from 'prop-types'
import { Avatar, Fab, Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'
import { Person as PersonIcon } from '@mui/icons-material'
import { memo } from 'react'

import { useGetNodeQuery } from '../../services/node'

const Button = memo(({nodeUri}) => {
    const { data = {}, isLoading } = useGetNodeQuery(nodeUri)

    if (isLoading) {
        return null
    }

    return (
        <Tooltip title="Manage Owner">
            <Fab color="secondary" component={Link} size="small" state={{modal: true}} to={`/${nodeUri}/owner`}>
                {data.assigned_user ? (
                    <Avatar
                        src={data.assigned_user.profile_picture} />
                ) : (
                    <PersonIcon />
                )}
            </Fab>
        </Tooltip>
    )
})

Button.propTypes = {
    nodeUri: PropTypes.string.isRequired,
}

export default Button
