import PropTypes from 'prop-types'
import { Fab, Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'
import { TrackChanges as TrackChangesIcon } from '@mui/icons-material'
import { memo } from 'react'

import Icon from './Icon'
import { useGetNodeQuery } from '../../services/node'

const Button = memo(({nodeUri}) => {
    const { data = {}, isLoading } = useGetNodeQuery(nodeUri)

    if (isLoading) {
        return null
    }

    const hasTarget = data.target_duration !== null && data.target_progress !== null

    return (
        <Tooltip title="Manage Target">
            <Fab color="secondary" component={Link} size="small" state={{modal: true}} to={`/${nodeUri}/target`}>
                {hasTarget ? (
                    <Icon
                        nodeUri={nodeUri} />
                ) : (
                    <TrackChangesIcon />
                )}
            </Fab>
        </Tooltip>
    )
})

Button.propTypes = {
    nodeUri: PropTypes.string.isRequired,
}

export default Button
