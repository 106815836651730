import { Route, Routes as BaseRoutes } from 'react-router-dom'
import { Fragment, memo, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import GuestLayout from '../components/GuestLayout'
import ProtectedLayout from '../components/ProtectedLayout'
import ModalLayout from '../components/ModalLayout'

import Login from './Login'
import Profile from './Profile'
import Security from './Security'

import Dashboard from './Dashboard'

import NodeChildren from './Node/Children'

import Owner from './Owner'
import Target from './Target'
import Typeform from './Typeform'

import ViewContent from './Content/View'

import Timeplan from './Timeplan'

import { setLastLocation } from '../reducers/uiSlice'

const Routes = memo(() => {
    const dispatch = useDispatch()
    const location = useLocation()
    const shadowLocation = useRef(location)

    useEffect(() => {
        if (location.state?.modal) {
            return
        }

        dispatch(setLastLocation(location.pathname))
        shadowLocation.current = location.pathname
    }, [location.state?.modal, location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Fragment>
            <BaseRoutes location={location.state?.modal ? shadowLocation.current || location : location}>
                <Route element={<GuestLayout />}>
                    <Route element={<Login />} path="/login" />
                </Route>
                <Route element={<ProtectedLayout />}>
                    <Route element={<Dashboard />} path="/" />
                    <Route element={<NodeChildren />} path="/:nodeUri" />
                    <Route element={<Profile />} path="/profile" />
                    <Route element={<Security />} path="/security" />
                </Route>
                {ModalRoutes}
            </BaseRoutes>
            {location.state?.modal && (
                <BaseRoutes>
                    {ModalRoutes}
                </BaseRoutes>
            )}
        </Fragment>
    )
})

const ModalRoutes = (
    <Route element={<ModalLayout />}>
        <Route element={<ViewContent />} path="/content/:slug" />
        <Route element={<Typeform />} path="/form/:slug" />

        <Route path=":nodeUri">
            <Route element={<Owner />} path="owner" />
            <Route element={<Target />} path="target" />
            <Route element={<Timeplan />} path="timeplan" />
        </Route>
    </Route>
)

export default Routes
