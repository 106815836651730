import { Paper, Typography } from '@mui/material'
import { Fragment, memo } from 'react'
import { format as formatDate } from 'date-fns'

import Value from '../Value'

const Confirm = memo(({date_due: dateDue, date_initiated: dateInitiated, format, prefix, service, start_value: startValue, suffix, value}) => {
    const styles = useStyles()

    const formattedLabel = useFormattedLabel(format)
    const formattedDateInitiated = useFormattedDate(dateInitiated)
    const formattedDateDue = useFormattedDate(dateDue)

    const showValues = Boolean(service.label !== 'internal.aggregate' && ! ['boolean'].includes(format) && startValue !== undefined)

    return (
        <Paper sx={styles.wrapper}>
            <Typography variant="subtitle2">Format</Typography>
            <Typography color="primary" variant="h6">{formattedLabel}</Typography>
            <Typography variant="subtitle2">Start date</Typography>
            <Typography color="primary" variant="h6">{formattedDateInitiated}</Typography>
            <Typography variant="subtitle2">Due date</Typography>
            <Typography color="primary" variant="h6">{formattedDateDue}</Typography>
            {showValues && (
                <Fragment>
                    <Typography variant="subtitle2">Start value</Typography>
                    <Value
                        color="primary"
                        format={format}
                        prefix={prefix}
                        suffix={suffix}
                        value={startValue}
                        variant="h6" />
                    <Typography variant="subtitle2">Target value</Typography>
                    <Value
                        color="primary"
                        format={format}
                        prefix={prefix}
                        suffix={suffix}
                        value={value}
                        variant="h6" />
                </Fragment>
            )}
        </Paper>
    )
})

const useFormattedDate = (date) => formatDate(new Date(date), 'do MMMM y')

const useFormattedLabel = (format) => {
    switch (format) {
        case 'boolean':
            return 'Met / not met'
        case 'decimal':
            return 'Decimal number'
        case 'integer':
            return 'Whole number'
        default:
            return ''
    }
}

const useStyles = () => ({
    wrapper: {
        display: 'grid',
        gridRowGap: 2,
        marginY: 2,
        paddingX: 2,
        paddingY: 2,
    },
})

export default Confirm
