import { combineReducers } from 'redux'

import auth from './authSlice'
import stash from './stashSlice'
import ui from './uiSlice'
import { api } from '../services/api'

const rootReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    auth, stash, ui,
})

export default rootReducer
