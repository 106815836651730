import { NODES_TAG, TYPEFORMS_TAG } from '../common/tagTypes'
import { api } from './api'

export const typeformApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getTypeform: builder.query({
            query: (url) => ({
                url: `/typeform/${url}`,
            }),
            providesTags: [TYPEFORMS_TAG],
        }),
        storeTypeformResponse: builder.mutation({
            query: (body) => ({
                url: `/typeform-response`,
                method: 'POST',
                body,
            }),
            invalidatesTags: [NODES_TAG, TYPEFORMS_TAG],
        }),
    }),
})

export const { useGetTypeformQuery, useStoreTypeformResponseMutation } = typeformApi
