import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { memo } from 'react'
import { format as formatDate, parseISO } from 'date-fns'

const DateAnswer = memo(({date}) => {
    return (
        <Typography variant="subtitle2" sx={styles.text}>{formatDate(parseISO(date), 'do MMMM y')}</Typography>
    )
})

const styles = {
    text: {
        whiteSpace: 'pre-wrap',
    },
}

DateAnswer.propTypes = {
    date: PropTypes.string.isRequired,
}

export default DateAnswer
