import { Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import parse, { domToReact } from 'html-react-parser'

const useGhostContent = (html) => {
    const options = {
        replace: (node) => {
            if (node.type === 'tag' && node.name === 'a' && node.attribs?.href.match(`^${document.location.origin}`)) {
                return (
                    <Link component={RouterLink} state={{modal: true}} to={node.attribs.href.replace(document.location.origin, '')} underline="hover">{domToReact(node.children, options)}</Link>
                )
            }
        }
    }

    return parse(html, options)
}

export default useGhostContent
