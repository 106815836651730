import { Box, Button, Typography } from '@mui/material'
import { Fragment, memo, useEffect, useRef, useState } from 'react'
import { Widget } from '@typeform/embed-react'
import { useParams } from 'react-router-dom'

import Loadable from '../components/Loadable'
import Response from '../components/Typeform/Response'
import useGoBack from '../hooks/useGoBack'
import { useGetTypeformQuery, useStoreTypeformResponseMutation } from '../services/typeform'

const Typeform = memo(() => {
    const hasSubmitted = useRef(false)
    const goBack = useGoBack()

    const { slug } = useParams()

    const [ isUpdating, setIsUpdating ] = useState(false)

    const { data = {}, isError, isLoading: isLoadingGetTypeformQuery, refetch } = useGetTypeformQuery(slug)
    const [ storeTypeformResponse, { isLoading: isLoadingStoreTypeformResponse } ] = useStoreTypeformResponseMutation()

    useEffect(() => {
        if (! hasSubmitted.current) {
            return
        }

        if (isLoadingStoreTypeformResponse) {
            return
        }

        goBack()
    }, [hasSubmitted, isLoadingStoreTypeformResponse]) // eslint-disable-line react-hooks/exhaustive-deps

    const handlePressUpdate = () => setIsUpdating(true)

    const handleSubmit = ({response_id}) => {
        storeTypeformResponse({
            typeform_id: data.typeform_id,
            response_id,
        })

        hasSubmitted.current = true
    }

    const isLoading = isLoadingGetTypeformQuery || isLoadingStoreTypeformResponse
    const showResponse = data.response && ! isUpdating

    return (
        <Box sx={styles.wrapper}>
            <Loadable error={isError} isLoading={isLoading} refetch={refetch}>
                {showResponse ? (
                    <Fragment>
                        <Response
                            slug={slug} />
                        {data.can_complete && (
                            <Button color="secondary" onClick={handlePressUpdate} size="small" sx={styles.button} variant="outlined">Update Responses</Button>
                        )}
                    </Fragment>
                ) : (
                    data.can_complete ? (
                        <Widget
                            id={data.typeform_id}
                            onSubmit={handleSubmit}
                            style={styles.content} />
                    ) : (
                        <Box sx={styles.errorWrapper}>
                            <Typography align="center" color="secondary" variant="h6">The owner of this goal is reposonsible for completing this.</Typography>
                        </Box>
                    )
                )}
            </Loadable>
        </Box>
    )
})

const styles = {
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100vh',
        overflow: 'hidden',
        width: (theme) => ({
            xs: '100%',
            md: theme.breakpoints.values.sm,
        }),
    },
    content: {
        height: '100vh',
        width: '100%',
    },
    button: {
        alignSelf: 'flex-end',
        mx: 5
    },
    errorWrapper: {
        alignItems: 'center',
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        px: 5,
        py: 3,
    },
}

export default Typeform
