import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { memo } from 'react'

const TextAnswer = memo(({text}) => {
    return (
        <Typography variant="subtitle2" sx={styles.text}>{text}</Typography>
    )
})

const styles = {
    text: {
        whiteSpace: 'pre-wrap',
    },
}

TextAnswer.propTypes = {
    text: PropTypes.string.isRequired,
}

export default TextAnswer
