import AdapterDateFns from '@mui/lab/AdapterDateFns'
import enGBLocale from 'date-fns/locale/en-GB'
import { BrowserRouter } from 'react-router-dom'
import { CssBaseline } from '@mui/material'
import { LocalizationProvider } from '@mui/lab'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'

import Loading from './components/Loading'
import Routes from './screens/Routes'
import useTheme from './common/theme'
import { persistor, store } from './store'

function App() {
    const theme = useTheme()

    return (
        <Provider store={store}>
            <PersistGate loading={<Loading />} persistor={persistor}>
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={enGBLocale}>
                        <CssBaseline />
                        <BrowserRouter>
                            <Routes />
                        </BrowserRouter>
                    </LocalizationProvider>
                </ThemeProvider>
            </PersistGate>
        </Provider>
    )
}

export default App
