export const NODE_TAG = 'Node'
export const NODES_TAG = 'Nodes'

export const TARGET_TAG = 'Target'
export const TARGETS_TAG = 'Targets'

export const TYPEFORM_TAG = 'Typeform'
export const TYPEFORMS_TAG = 'Typeforms'

export const USER_TAG = 'User'
export const USERS_TAG = 'Users'
