import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import { CloudDownload as CloudDownloadIcon } from '@mui/icons-material'
import { memo } from 'react'

const FileAnswer = memo(({file_url}) => {
    return (
        <Button component="a" href={file_url} startIcon={<CloudDownloadIcon />} variant="contained">
            Download
        </Button>
    )
})

FileAnswer.propTypes = {
    file_url: PropTypes.string.isRequired,
}

export default FileAnswer
