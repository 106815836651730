import PropTypes from 'prop-types'
import { Box, Button, Tooltip, Typography } from '@mui/material'
import { Fragment, cloneElement, memo } from 'react'
import { Functions as FunctionsIcon } from '@mui/icons-material'
import { useDispatch } from 'react-redux'

import { stashTarget } from '../../../reducers/stashSlice'

const SelectService = memo(({nodeUri, service = {}}) => {
    const dispatch = useDispatch()
    const services = useServices()
    const styles = useStyles()

    const handleSelect = (label) => dispatch(
        stashTarget({
            nodeUri,
            data: {
                service: {
                    ...service,
                    label,
                },
            },
        })
    )

    const handleClickManual = () => handleSelect('internal.manual')

    return (
        <Fragment>
            <Box sx={styles.wrapper}>
                {services.map((props) => (
                    <ServiceButton
                        key={`service-${props.id}`}
                        onClick={handleSelect}
                        service={service}
                        {...props} />
                ))}
            </Box>
            <ManualServiceButton
                onClick={handleClickManual}
                service={service} />
        </Fragment>
    )
})

const ServiceButton = memo(({description, icon, id, name, onClick, service}) => {
    const isSelected = service.label === id

    const styles = useStyles({isSelected})

    const handleClick = () => onClick(id)


    return (
        <Button color="secondary" onClick={handleClick} sx={[styles.buttonBase, styles.button]} variant={isSelected ? 'outlined' : 'text'}>
            {cloneElement(typeof icon === 'string' ? <Box component="img" src={icon} /> : icon, {sx: styles.icon})}
            <Typography color="inherit" variant="subtitle2">{name}</Typography>
            <Typography color="inherit" variant="caption">{description}</Typography>
        </Button>
    )
})

const ManualServiceButton = memo(({onClick, service}) => {
    const isSelected = service.label === 'internal.manual'

    const styles = useStyles({isSelected})

    return (
        <Fragment>
            <Typography variant="subtitle2" paragraph>Looking for a use&#8209;case we don&rsquo;t cover yet?</Typography>
            <Tooltip title="Track your target by manually updating its value.">
                <Button color="secondary" onClick={onClick} sx={styles.buttonBase} variant={isSelected ? 'outlined' : 'text'}>Manual Target</Button>
            </Tooltip>
        </Fragment>
    )
})

const useServices = () => ([
    {
        id: 'google.analytics',
        name: 'Google Analytics',
        description: 'Track your target against a Google Analytics metric.',
        icon: '//s3-eu-west-1.amazonaws.com/goalatlas.assets/targets/google_analytics_logo.png',
    },
    {
        id: 'google.sheets',
        name: 'Google Sheets',
        description: 'Track your target against a value in a Google Sheets document.',
        icon: '//s3-eu-west-1.amazonaws.com/goalatlas.assets/targets/google_sheets_logo.png',
    },
    {
        id: 'internal.aggregate',
        name: 'Aggregate',
        description: 'Track against the aggregated value of other targets.',
        icon: <FunctionsIcon />
    }
])

const useStyles = ({isSelected} ={}) => ({
    wrapper: {
        display: 'grid',
        columnGap: 2,
        gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
        marginBottom: 4,
        rowGap: 2,
    },
    buttonBase: {
        borderColor: isSelected ? undefined : 'transparent',
        borderStyle: 'solid',
        borderWidth: 1,
        paddingX: 3,
        paddingY: 0.75,
    },
    button: {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'flex-start',
        textAlign: 'left',
    },
    icon: {
        alignSelf: 'center',
        color: 'primary.main',
        fontSize: (theme) => theme.spacing(10),
        height: (theme) => theme.spacing(15),
        marginBottom: 2,
        maxWidth: '100%',
        objectFit: 'contain',
    },
})

SelectService.propTypes = {
    nodeUri: PropTypes.string.isRequired,
    service: PropTypes.shape({
        label: PropTypes.string,
    }),
}

export default SelectService
