import { useGetDashboardQuery } from '../services/dashboard'

const useNodes = (uri) => {
    const { data = {} } = useGetDashboardQuery()

    const {
        children: childInstanceIds = [], id: currentInstanceId, node_id: currentNodeId,
    } = data.map?.instances?.find(({id, node_uri}) => (uri && node_uri === uri) || (! uri && id === data.map?.default_instance_id)) || {}

    const defaultNodeUri = data.map?.instances?.find(({id}) => id === data.map?.default_instance_id)?.node_uri || ''

    return {
        childNodes: childInstanceIds.map((item) => data.map?.instances.find(({id}) => id === item)).map(({node_id}) => data.map?.nodes?.find(({id}) => id === node_id)).filter(({active, locked}) => active && ! locked) || [],
        currentInstance: data.map?.instances.find(({id}) => id === currentInstanceId),
        currentNode: data.map?.nodes?.find(({id}) => id === currentNodeId) || {},
        defaultNodeName: data.map?.nodes?.find(({uri}) => uri === defaultNodeUri)?.name || '',
        instances: data.map?.instances || [],
        nodes: data.map?.nodes || [],
        defaultNodeUri,
    }
}

export default useNodes
