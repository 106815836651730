import PropTypes from 'prop-types'
import { Box, InputAdornment, TextField } from '@mui/material'
import { memo } from 'react'
import { useDispatch } from 'react-redux'

import { stashTarget } from '../../../reducers/stashSlice'

const SetValues = memo(({nodeUri, prefix, start_value: startValue, suffix, value}) => {
    const dispatch = useDispatch()
    const styles = useStyles()

    const handleChangeStartValue = (event) => dispatch(
        stashTarget({
            nodeUri,
            data: {
                start_value: event.target.value,
            },
        })
    )

    const handleChangeValue = (event) => dispatch(
        stashTarget({
            nodeUri,
            data: {
                value: event.target.value,
            }
        })
    )

    return (
        <Box sx={styles.wrapper}>
            <TextField
                InputProps={{
                    endAdornment: suffix ? (
                        <InputAdornment position="end">
                            {suffix}
                        </InputAdornment>
                    ) : null,
                    startAdornment: prefix ? (
                        <InputAdornment position="start">
                            {prefix}
                        </InputAdornment>
                    ) : null
                }}
                label="Start value"
                margin="normal"
                onChange={handleChangeStartValue}
                value={startValue} />
            <TextField
                InputProps={{
                    endAdornment: suffix ? (
                        <InputAdornment position="end">
                            {suffix}
                        </InputAdornment>
                    ) : null,
                    startAdornment: prefix ? (
                        <InputAdornment position="start">
                            {prefix}
                        </InputAdornment>
                    ) : null
                }}
                label="Target value"
                margin="normal"
                onChange={handleChangeValue}
                value={value} />
        </Box>
    )
})

const useStyles = () => ({
    wrapper: {
        display: 'grid',
        gridAutoColumns: 'max-content',
        gridRowGap: 3,
    },
})

SetValues.propTypes = {
    nodeUri: PropTypes.string.isRequired,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.number, PropTypes.string,
    ]),
}

export default SetValues
