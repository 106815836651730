import { Box, SvgIcon } from '@mui/material'
import { Link as RouterLink, Navigate } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import { memo } from 'react'
import { useSelector } from 'react-redux'

import { ReactComponent as Icon } from '../assets/icon.svg'

const GuestLayout = memo(() => {
    const { isAuthed } = useSelector(({auth}) => auth)

    if (isAuthed) {
        return (
            <Navigate
                to="/" />
        )
    }

    return (
        <Box sx={styles.wrapper}>
            <Box component="header" sx={styles.headerWrapper}>
                <Box component={RouterLink} style={styles.iconWrapper} to="/">
                    <SvgIcon
                        component={Icon}
                        sx={styles.icon}
                        viewBox="0 0 40 40" />
                </Box>
            </Box>
            <Box sx={styles.content}>
                <Outlet />
            </Box>
        </Box>
    )
})

const styles = {
    wrapper: {
        minHeight: '100vh',
        minWidth: 320,
    },
    content: {
        display: 'flex',
        minHeight: '100vh',
        py: 12,
    },
    headerWrapper: {
        display: 'flex',
        p: {
            xs: 3,
            md: 7,
        },
        pb: {
            xs: 3,
            md: 0,
        },
        position: 'absolute',
        width: '100vw',
    },
    iconWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        fontSize: 40,
    },
}

export default GuestLayout
