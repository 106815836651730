import { NODE_TAG } from '../common/tagTypes'
import { api } from './api'

export const nodeApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getNode: builder.query({
            query: (url) => ({
                url: `/node/${url}`,
            }),
            providesTags: ({uri}) => [{
                type: NODE_TAG,
                id: uri,
            }],
        }),
        updateNode: builder.mutation({
            query: ({uri, data: body}) => ({
                url: `/node/${uri}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (_result, _error, {uri}) => [{
                type: NODE_TAG,
                id: uri,
            }]
        }),
    }),
})

export const { useGetNodeQuery, useUpdateNodeMutation } = nodeApi
