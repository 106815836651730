import PropTypes from 'prop-types'
import { Box, TextField } from '@mui/material'
import { DatePicker } from '@mui/lab'
import { memo } from 'react'
import { useDispatch } from 'react-redux'

import { stashTarget } from '../../../reducers/stashSlice'

const SetDates = memo(({date_due: dateDue = null, date_initiated: dateInitiated = null, nodeUri}) => {
    const dispatch = useDispatch()
    const styles = useStyles()

    const handleChangeDateInitiated = (date) => dispatch(stashTarget({
        nodeUri,
        data: {
            date_initiated: date.toISOString(),
        },
    }))

    const handleChangeDateDue = (date) => dispatch(stashTarget({
        nodeUri,
        data: {
            date_due: date.toISOString()
        },
    }))

    const minDateDue = dateInitiated ? new Date(dateInitiated).setDate(new Date(dateInitiated).getDate() + 1) : undefined

    return (
        <Box sx={styles.wrapper}>
            <DatePicker
                onChange={handleChangeDateInitiated}
                renderInput={(params) => <TextField {...params} />}
                value={dateInitiated} />
            <DatePicker
                minDate={minDateDue}
                onChange={handleChangeDateDue}
                renderInput={(params) => <TextField {...params} />}
                value={dateDue} />
        </Box>
    )
})

const useStyles = () => ({
    wrapper: {
        display: 'grid',
        gridAutoColumns: 'max-content',
        rowGap: 3,
    },
})

SetDates.propTypes = {
    date_due: PropTypes.oneOfType([
        PropTypes.string, PropTypes.object,
    ]),
    date_initiated: PropTypes.oneOfType([
        PropTypes.string, PropTypes.object,
    ]),
    nodeUri: PropTypes.string.isRequired,
}

export default SetDates
