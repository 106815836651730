import { Box, Paper, Typography } from '@mui/material'
import { memo } from 'react'
import { useParams } from 'react-router-dom'

import Answer from './Answer'
import { useGetTypeformQuery } from '../../services/typeform'

const Response = memo(() => {
    const { slug } = useParams()
    const { data = {} } = useGetTypeformQuery(slug)

    if (! data.response || ! data.response.definition?.fields || ! data.response.answers) {
        return null
    }

    const items = data.response.definition.fields.filter((_, index) => data.response.answers[index]).map((item, index) => ({
        ...item,
        answer: data.response.answers[index],
    }))

    return (
        <Box sx={styles.wrapper}>
            {items.map(({answer, id, title}) => {
                return (
                    <Paper key={id} sx={styles.item}>
                        <Typography color="secondary" mb={2} variant="body2">{title}</Typography>
                        <Answer
                            {...answer} />
                    </Paper>
                )
            })}
        </Box>
    )
})

const styles = {
    wrapper: {
        mx: 5,
    },
    item: {
        p: 3,
        my: 3,
    },
}

export default Response
