import { useSelector } from 'react-redux'

import { useGetUserQuery } from '../services/user'

const useCurrentUser = () => {
    const { auth } = useSelector((state) => state)
    const { data = {} } = useGetUserQuery(auth.userId)

    return auth.isAuthed ? data : null
}

export default useCurrentUser
