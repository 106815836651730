import PropTypes from 'prop-types'
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { memo, useState } from 'react'

const PasswordInput = memo(({autoComplete = '', error = false, fullWidth = false, helperText = '', label = '', onChange, sx = {}, value}) => {
    const [ showPassword, setShowPassword ] = useState(false)

    const handleClickTogglePassword = () => setShowPassword((currentValue) => ! currentValue)

    return (
        <FormControl fullWidth={fullWidth} sx={sx}>
            <InputLabel>{label}</InputLabel>
            <OutlinedInput
                autoComplete={autoComplete}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton edge="end" onClick={handleClickTogglePassword}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
                error={error}
                label="Password"
                onChange={onChange}
                type={showPassword ? 'text' : 'password'}
                value={value} />
            {error && (
                <FormHelperText error>{helperText}</FormHelperText>
            )}
        </FormControl>
    )
})

PasswordInput.propTypes = {
    autoComplete: PropTypes.string,
    error: PropTypes.bool,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string), PropTypes.string,
    ]),
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
}

export default PasswordInput
