import { Container } from '@mui/material'
import { memo } from 'react'
import { useParams } from 'react-router-dom'

import Breadcrumbs from '../components/Breadcrumbs'
import Loadable from '../components/Loadable'
import Node from '../components/Browser/Node'
import useNodes from '../hooks/useNodes'
import { useGetDashboardQuery } from '../services/dashboard'

const Content = memo(() => {
    const { nodeUri } = useParams()
    const { defaultNodeUri } = useNodes(nodeUri)
    const { error, isLoading, refetch } = useGetDashboardQuery()

    return (
        <Loadable error={error} isLoading={isLoading} refetch={refetch}>
            <Container sx={styles.container}>
                <Breadcrumbs
                    sx={styles.breadcrumbs} />
                <Node
                    uri={nodeUri || defaultNodeUri}
                    horizontal />
            </Container>
        </Loadable>
    )
})

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
    },
    wrapper: {
        display: 'grid',
        flex: 1,
        columnGap: 3,
        gridAutoColumns: (theme) => `minmax(${theme.spacing(40)}, 0.33fr)`,
        gridAutoRows: 'min-content',
        marginTop: 3,
        minHeight: '100%',
        overflowX: 'auto',
        rowGap: 3,
        gridAutoFlow: {
            lg: 'column',
        },
    },
    breadcrumbs: {
        marginBottom: 5,
    },
}

export default Content
