import Cookies from 'js-cookie'
import { createSlice } from '@reduxjs/toolkit'

export const forgetCookie = () => Cookies.remove('_token')

const initialState = {
    isAuthed: false,
    isRemembered: false,
    userId: null,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, {payload}) => {
            state.isAuthed = true
            state.isRemembered = payload.remember
            state.userId = payload.user.id
        },
        forgetCredentials: (state) => {
            state.isAuthed = false
            state.isRemembered = false
            state.userId = null
        },
    },
})

export const { forgetCredentials, setCredentials } = authSlice.actions

export default authSlice.reducer
