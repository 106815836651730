import { Drawer } from '@mui/material'
import { Navigate, Outlet } from 'react-router-dom'
import { memo } from 'react'
import { useSelector } from 'react-redux'

import useGoBack from '../hooks/useGoBack'

const ModalLayout = memo(() => {
    const goBack = useGoBack()

    const { isAuthed } = useSelector(({auth}) => auth)

    if (! isAuthed) {
        return (
            <Navigate
                to="/login" />
        )
    }

    return (
        <Drawer anchor="right" onClose={goBack} open>
            <Outlet />
        </Drawer>
    )
})

export default ModalLayout
